import React, {useState} from 'react';
import Navbar from './NavBar';
import Presentation from './Presentation';
import ContactIcon from './ContactIcon';

function Main(){

    return(
        <div>
            <Navbar/>
            <Presentation/>
            <ContactIcon/>
        </div>
    );
}

export default Main;