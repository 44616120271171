import React from "react";
import { useTranslation } from 'react-i18next';
import './Cinema.css';
import './Music.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Quill from '../../Musiques/Quill.wav';
import JazzJam from '../../Musiques/JazzJam.mp3';
import Kian from '../../Musiques/KianDabaghi.wav';
import Vincent from '../../Musiques/VincentDesrochers.wav';

function Music(){

    const { t, i18n } = useTranslation();
    const data = t("projects.music.objects", { returnObjects: true });
    const dataStudioRecording = t("projects.music.studioRecordings.objects", { returnObjects: true });
    const listMusiques = [Quill, JazzJam];
    const listStudioRecordings = [Kian,Vincent];

    return (
        <div id="cinema-container">
            {data.map((element, index) => (
                <div key={index} className="cinema-element">
                    <h1>{element.title}</h1>
                    <h2>{element.role}</h2>
                    <p>{element.description}</p>
                    <audio controls className="cinema-audio">
                        <source src={listMusiques[index]} type="audio/wav" />
                        Your browser does not support audio.
                    </audio>
                </div>
            ))}
            <div className="cinema-element">
                <h1>{t("projects.music.studioRecordings.title")}</h1>
                <h2>{t("projects.music.studioRecordings.role")}</h2>
                {dataStudioRecording.map((element, index) => (
                <div className="music-element">
                    <p>{element.description}</p>
                    <audio controls className="cinema-audio">
                        <source src={listStudioRecordings[index]} type="audio/wav" />
                        Your browser does not support audio.
                    </audio>
                    {element.linkedIn && (
                            <a href={element.linkedIn} target="_blank" rel="noopener noreferrer" className="linkedin-link">
                                <FontAwesomeIcon icon={faLinkedin} size="2x" />
                            </a>
                    )}
                </div>
            ))}
            </div>
        </div>
    );
}
 
export default Music;