import PhotoArmand from '../Images/ProfileBoard.png';
import React from 'react';
import { useTranslation } from 'react-i18next';
import "./Presentation.css";
import "./About.css";

function About(){

    const { t, i18n } = useTranslation();

    return(
        <div id="about-container">
            <h1>{t("about.title")}</h1>
            <img src={PhotoArmand} className='photo' alt='Armand Lefebvre'/>
            <p>{t("about.line1")}</p>
            <p>{t("about.line2")}</p>
            <p>{t("about.line3")}</p>
            <p>{t("about.line4")}</p>
        </div>
    );

}

export default About;