import React, {useState} from "react";
import { useTranslation } from 'react-i18next';
import "./Projects.css";
import Cinema from './Projects/Cinema';
import Music from "./Projects/Music";
import Videogames from "./Projects/Videogames";

function Projects(){

    const { t, i18n } = useTranslation();

    const [activeComponent, setActiveComponent] = useState('Cinema');

    const renderComponent = () => {
        switch (activeComponent) {
            case 'Cinema':
                return <Cinema />;
            case 'Videogame':
                return <Videogames />;
            case 'Music':
                return <Music />;
            default:
                return <Cinema />;
        }
    };

    return(
        <div id="projects-container">
            <h1>{t("projects.title")}</h1>
            <div id="menuProjet">
                <button
                    className={activeComponent === 'Cinema' ? 'active' : ''}
                    onClick={() => setActiveComponent('Cinema')}
                >
                    {t("projects.cinema.title")}
                </button>
                <button
                    className={activeComponent === 'Videogame' ? 'active' : ''}
                    onClick={() => setActiveComponent('Videogame')}
                >
                    {t("projects.videogames.title")}
                </button>
                <button
                    className={activeComponent === 'Music' ? 'active' : ''}
                    onClick={() => setActiveComponent('Music')}
                >
                    {t("projects.music.title")}
                </button>
            </div>
            <div className="content">
                {renderComponent()}
            </div>
        </div>
    );

}

export default Projects;