import React from "react";
import { useTranslation } from 'react-i18next';
import './Cinema.css';
import GodEaterVideo from '../../Videos/god_eater_eps1_downmix_stereo_armand_lefebvre.mp4';

function Cinema(){

    const { t, i18n } = useTranslation();
    const data = t("projects.cinema.objects", { returnObjects: true });
    const listVideo = [GodEaterVideo, GodEaterVideo];

    return(
        <div id="cinema-container">
            {data.map((element, index) => (
                <div key={index} className="cinema-element">
                    <h1>{element.title}</h1>
                    <h2>{element.language}</h2>
                    <h2>{element.duration}</h2>
                    <p>{element.description}</p>
                    {element.url ? <div>
                        <iframe src={element.url} title={element.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div> 
                    : <div>
                        <video controls className="cinema-video">
                        <source src={listVideo[index]} type="video/mp4" />
                        Your browser does not support the video tag.
                        </video>
                    </div>}
                    <p className="infos">{t("projects.infos.downmix")}</p>
                </div>
            ))}
        </div>
    );
}

export default Cinema;