import React, {useState} from 'react';
import "./Presentation.css";
import ArrowDown from '../Images/arrow-down.svg';
import About from './About';
import Projects from './Projects';

function Presentation(){

    const [contentActive, setContentActive] = useState(false);

    const scrollToContent = () => {
        setContentActive(true);
    };

    return(

        <div id="presentation-container">
            <div className={contentActive ? 'inactif' : ''}>
                <h1>Armand Lefebvre</h1>
                <h2>Sound Designer, Sound Mixer, Recording technician</h2>
                <div className="scroll-down-arrow" id="scrollDownArrow" onClick={scrollToContent}>
                    <img src={ArrowDown} alt="Scroll Down" />
                </div>
            </div>
            {contentActive && (
                <div id="content" className={contentActive ? 'active' : ''}>
                    <About/>
                    <Projects/>
                </div>
            )}
        </div>
    );
}

export default Presentation;