import React from "react";
import { useTranslation } from 'react-i18next';
import './Cinema.css';

function Videogames(){

    const { t, i18n } = useTranslation();
    const data = t("projects.videogames.objects", { returnObjects: true });

    return(
        <div id="cinema-container">
            {data.map((element, index) => (
                <div key={index} className="cinema-element">
                    <h1>{element.title}</h1>
                    <h2>{element.language}</h2>
                    <h2>{element.duration}</h2>
                    <p>{element.description}</p>
                    <div>
                        <iframe src={element.url} title={element.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <p className="infos">{t("projects.infos.downmix")}</p>
                </div>
            ))}
        </div>
    );
}
 
export default Videogames;