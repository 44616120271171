import React from 'react';
import { useTranslation } from 'react-i18next';
import './Navbar.css';

const Navbar = () => {
  const { i18n } = useTranslation();

  const changerLangue = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav>
      <div>
        <button onClick={() => changerLangue('en')}>EN</button>
        <button onClick={() => changerLangue('fr')}>FR</button>
      </div>
    </nav>
  );
};

export default Navbar;