import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faAddressBook, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import './ContactIcon.css';
import { useTranslation } from 'react-i18next';

function ContactIcon(){
    const [isHovered, setIsHovered] = useState(false);
    const { t, i18n } = useTranslation();

    return (
        <div
            className="contact-icon"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => setIsHovered(!isHovered)}
        >
            <FontAwesomeIcon icon={faAddressBook} />
            {isHovered && (
                <div className="contact-menu">
                    <a href="https://www.linkedin.com/in/armandlefebvre/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href='mailto:armandlefebvre.mailbox@gmail.com'>
                        <FontAwesomeIcon icon={faMailBulk} /> {t('contact.mail')}
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100008299750758" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook} /> Facebook
                    </a>
                </div>
            )}
        </div>
    );
};

export default ContactIcon;